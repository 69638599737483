.buttons-save {
  overflow: hidden;
}
.save-changepassword-btn {
  background: #cd171f;
  border-radius: 5px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 12px 32px;
  border: none;
  float: right;
  margin-right: 25px;
  margin-bottom: 20px;
}
.changepasswordBody {
  margin-top: 32px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}
