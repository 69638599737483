.MuiTablePagination-root:last-child {
  overflow-x: hidden;
}

.track-logo {
  margin-top: 30px;
  margin-left: 30px;
}
.track-table {
  padding-top: 0px;
}
.checkbox {
  accent-color: #ed1c24;
}
.checkboxbox {
  position: relative;
  left: 290px;
}
.noboid {
  color: rgb(155, 144, 144);
  font-size: 14px;
  vertical-align: middle;
  margin-left: 5px;
}
.track-grid {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  justify-content: center;
  align-items: flex-end;
}

.track-label {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #596579;
  margin-bottom: 3px;
}

.table-track {
  margin-top: 2.5rem;
}
/* track form css here */
.track-form-select {
  border: 2px solid #cfd0d7;
  width: 250px;
  padding: 10px 5px;
  border-radius: 5px;
}
.track-form-select:focus {
  outline-color: #b5b6bc;
}

.track-form-input {
  border: 2px solid #cfd0d7;
  width: 240px;
  padding: 11px 5px;
  border-radius: 5px;
}

.track-form-input:focus {
  outline-color: #b5b6bc;
}

@media screen and (max-width: 990px) {
  .track-grid {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }
}

@media screen and (max-width: 660px) {
  .track-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .track-check-btn-main-div {
    display: flex;
    justify-content: center;
    width: 250px;
  }
}

/* file upload css h
                    e
                    r
                    e */
.track-file-upload1 {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.track-f-u-sub {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: fit-content;
}

.track-f-u-sub button {
  border: 2px dashed gray;
  color: #596579;
  background: #fcfcfd;
  padding: 8px 10px;
  width: 250px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.track-f-u-sub input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.track-f-u-sub input[type="file"]:hover {
  cursor: pointer;
}

/* track popup css  h
                    e
                    r
                    e */
.swal2-shown .swal2-popup {
  width: fit-content;
}
.swal2-shown .swal2-html-container {
  margin: 0;
}
.track-pop-contrags {
  width: 100%;
  margin-top: 0rem;
  display: flex;
  justify-content: center;
}

.track-congrats-p {
  font-size: 16px;
  line-height: 15px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 10px;
}

.track-ipo-result {
  display: flex;
  justify-content: center;
  padding: 20px 10px 10px 10px;
}
.track-ipo-result-p {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.track-pop-table2 {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  width: fit-content;
}

.track-pop-sub-table {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.track-table-col {
  display: flex;

  gap: 1.5rem;
}
.ttc-list1 {
  display: flex;
}
.ttc-list1 label:nth-child(1) {
  width: 116px;
}
.ttc-list1 label:nth-child(2) {
  width: 250px;
}
.track-pop-table-topic {
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 5px;
}
.ttc-list2 {
  display: flex;
}
.ttc-list2 label:nth-child(1) {
  width: 98px;
}

.track-pop-table-subtopic {
  font-size: 15px;
  text-align: left;
  margin: 0;
  padding: 5px;
}

@media screen and (max-width: 520px) {
  .track-pop-sub-table {
    flex-direction: column;
  }
}
