@import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap");
.login-login{
  font-size: 25px;
  line-height: 29px;
  color: #374253;  
  margin: 0%;
}
.logo{
  text-align: center;
  margin-bottom: 48px;
}
.body {
  background-color: #FFF;
  position: absolute;
  width: 100%;
  min-height: 100%;
}
.content {
  width: 25%;
  position: relative;
  /* bottom: 30px; */
  margin: 5% auto;
  /* margin-bottom: 0%; */
}
@media screen and (max-width:1500px) {
  .content{
    width: 50%;
  }
}
.login_content{
  background: #F6F6F6;
  border-radius: 10px;  
  padding: 30px;
  padding-top: 40px;
}
.login-input{
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #CFD0D7;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  margin: 8px 0px;
  padding: 12px;
}
.login-input:hover{
  border: 1px solid #CD171F;
}
.login-input:focus{
  outline: none;
  border: 1px solid #CD171F;
}
#errspan {
  cursor: pointer;
}
.check {
  /* margin: 25px 0%; */
  /* margin-right: 8px; */
  accent-color: #CD171F;
  /* height: 14px;
  width: 14px; */
}
.login-button {
  display: block;
  width: 100%;
  height: 50px;
  color: #fff;
  background: #CD171F;
  border-radius: 4px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin: 24px 0;
}
.login-forgotpassword{
  display: inline;
  color: #CD171F;
  float: right;
  font-size: 14px;
  cursor: pointer;
}
.login-line{
  margin: 8px 0%;
  align-items: baseline;
}
.login-rememberme{
  font-size: 14px;
  display: inline;
  padding-left: 5px;
  vertical-align: text-top;
}
.login-register{
  color: #CD171F;
  display: inline;
  cursor: pointer;
}
.login-bottom-text{
  margin: 30px 0%;
  font-size: 14px;
}
.login-text-bottom{
  width: 75%;
  margin: 24px auto;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8D909E;
}