.ipoissued-body {
  padding: 30px;
  background: #f6f6f6;
  min-height: 91.4vh;
}
/* .ipoissued-body-wh {
    width: 95.8vw;
    height: 92.1vh;
  } */
.apply-for-issue {
  display: flex;
  justify-content: center;
  align-items: center;
}
.apply-for-issue1 {
  margin: 20px 0px;
  width: 90%;
}
#apply-for-issue {
  font-size: 16px;
  line-height: 22px;

  color: #374253;
}
.apply-ipo-issued {
  display: flex;
  background: #ffffff;
  border: 2px solid #cfd0d7;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-top: 10px;
}

#ipo-issued-name {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #374253;
  font-weight: 400;
}
#ipoissued-p-ipo {
  background: #f6f6f6;
  padding: 5px;
  color: #ed1c24;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 10px;
}
#ipoissued-share-type {
  background: #f6f6f6;
  padding: 5px;
  color: #ed1c24;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px 10px;
}
.apply-ipo-issued-btn button {
  background: #ed1c24;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 12px 25px;
  font-weight: 400;
  font-size: 16px;
}

.apply-ipo-issued-btn button:hover {
  cursor: pointer;
  background: #cb242a;
}
