@import url('https://fonts.googleapis.com/css2?family=Inria+Sans&display=swap');
.payment-title{
    text-align: center;
    font-family: Inria Sans;
    text-transform: capitalize;
    font-size: 46px;
    color: #CD171F;
}
.text00{
    text-align: center;
    font-family: Inria Sans;
    text-transform: capitalize;
    /* font-size: 46px; */
    color: #CD171F;
}
.payment-table-1{
    width: 40%;
    margin: 0 auto;
    background: #f6f6f6;
    border: 2px solid #cfd0d7;
    box-sizing: border-box;
    border-radius: 10px;
}
.container-payment{
    padding: 30px 0;
    min-height: fit-content;

}
.payment-table-2{
    width: 30%;
    margin: 0 auto;
    background: #f6f6f6;
    border: 2px solid #cfd0d7;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
}
.line-payment{
    background-color: #CD171F;
    border: none;
    height: 2px;
}
.payment-partners{
    display: flex;
    justify-content: center;    
}
.payment-logo{
    height: 80px;
}
.payment-img-btn{
    border: 1px solid #CD171F;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
    margin: 25px 20px;
    padding: 15px 0px;
    width: 185px;
    font-weight: bold;
}
.payment-img-btn:hover{
    box-shadow: 0px 0px 5px rgba(86, 45, 45, 0.1);
    background-color: #f9f4f4;
    border: 1px solid #c68082;
}
.text1-payment{
    font-family: IBM Plex Sans;
    font-style: normal;
    color: #192638;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
}
.text2-payment{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    width: 70%;
    margin: 0px auto;
    color: #596579;
}
.buttonhome{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    background: #CD171F;
    border-radius: 5px;
    padding: 11px 12px;
    margin-top: 40px;
    border: none;
}
.success-content{
    width: 35%;
    margin:20.8vh auto;
    text-align: center;
}
.border0{
    border: none;
}
.margin15{
    margin-bottom: 15px;
}
@media screen and (max-width:1200px){
    .payment-table-1{
        width: 65%;
    }
}
.payment-scan{
    width: auto;
    height: auto;
}
.scan-to-pay{
    display: flex;
    justify-content: center;
    margin: 0px auto;
}
.payment-options{
    width: 70%;
    margin: 0px auto;
}
.margins-set{
    margin-top: -55px !important;
}
.transaction-id{
    font-size: 18px;
    color: gray;
    text-transform: capitalize;
}