.details {
  margin: 40px;
  margin-top: 60px;
}
@media screen and (max-width: 1200px) {
  .details {
    margin: 40px;
    margin-top: 60px;
  }
  @media screen and (max-width: 1200px) {
    .details {
      left: 27%;
      width: 60%;
      top: 120px;
    }
    .veh-btn-cancel-save {
      display: block;
    }
    .form-input:focus {
      outline: none;
    }
    .vehform-save-cancel-col {
      float: right;
      /* display: flex;
      flex-direction: row; */
    }
  }
  .veh-btn-cancel-save {
    float: right;
    border: none;
    font-family: Mukta;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 12px 24px;
    margin: 24px;
    margin-right: 0%;
    color: #596579;
    background: #ebedf1;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }
  #save-button {
    color: #ffffff;
    background: #cd171f;
    padding: 12px 30px;
  }
  .successfully-saved {
    background: #ccf5e1;
    float: right;
    border-left: 10px solid #3dae6c;
    padding: 15px;
    margin-top: 30px;
  }
  .icon-success {
    vertical-align: middle;
    margin-bottom: 5px;
    margin-right: 15px;
  }
  .text06 {
    font-family: Mukta;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #343a40;
  }
  .text07 {
    font-family: Mukta;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #343a40;
    margin-left: 42px;
    margin-right: 150px;
  }
  /* User Details Entry */
  #save-cancel-userdetails {
    margin-top: 130px;
  }
  #selected-topic {
    background: #3b4c6f;
    color: #f2f2f2;
    border-left: 4px solid #cd171f;
    padding-left: 26px;
  }
  .ipo-fields {
    width: 100%;
    margin-top: 20px;
  }
  #btn-cols {
    margin-top: 20px;
  }

  .form-input-voucher {
    width: 100%;
    height: 40px;
    outline: none;
    border: 2px solid #cfd0d7;
    border-radius: 5px;
    font-weight: normal;
    font-size: 16px;
    color: #596579;
  }

  .form-input-voucher:focus {
    outline: 0.5px solid #cfd0d7;
  }
}
.gap-maintain{
  margin-top: 0px !important;
}
.voucher-form{
  width: 93%;
  margin: 0 auto;
  margin-top: 20px;
}
.formik-form{
  display: flex !important;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.search-voucher{
  flex-direction: row !important;
}

.custom-button{
  flex: 0.4 !important;

}