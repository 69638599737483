.printable-area {
  display: block !important;
}
.print-btn {
  margin-top: 10px;
  background: #ed1c24;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 12px 25px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  float: right;
}
.three-btn {
  width: 350px;
  margin: 20px;
  cursor: pointer;
  background: #ed1c24;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.three-btn:hover {
  background-color: #dc5d62;
}

.view-uploaded-file {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.ctzenshp-img-frame2 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.ctzenshp-img-frame2 img {
  width: 100%;
}

@media print {
  .sidebar {
    display: none;
  }
  .top-bar {
    display: none;
  }
  .printable-area {
    display: block;
  }
  .print-btn {
    display: none;
  }
}