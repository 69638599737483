
.footer{
    background-color: #cd171f;
    color: white;
    text-align: center;
    position: absolute;
    width: 99vw;
    padding: 5px;
    bottom: 0 !important;
    margin-bottom: -30px;
}
.copyright-icon{
    margin: 0px 7px;
    color: #cd171f;
    background-color: white;
    border-radius: 50px;
    padding: 1px;
}