.create-user {
  padding: 50px 10px;
}
.text04 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #374253;
  display: inline;
}
#btn-selected {
  background: #cd171f !important;
  color: #ffffff !important;
}
.btn-details {
  background: #ebedf1;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border: none;
  outline: none;

  font-size: 14px;
  line-height: 20px;
  color: #cd171f;
  margin: 20px 8px;
  padding: 12px 24px;
}
.save-btn {
  background: #cd171f;
  border-radius: 5px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 12px 32px;
  border: none;
  float: right;
  margin-top: 40px;
  cursor: pointer;
}
.create-user-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  padding: 16px;
  padding-top: 0px;
}
#errspan {
  position: relative;
  bottom: 35px;
  right: 20px;
  float: right;
}
.createBody {
  margin-top: 32px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}
