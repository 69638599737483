.ipo-allot-main-input {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.ipo-allot-select {
  border: 1px solid #ed1c24;
  width: 250px;

  padding: 10px 5px;
  border-radius: 5px;
}
.ipo-allot-select:focus {
  outline-color: #ed1c24;
}
.ipo-allot-input:focus {
  outline-color: #ed1c24;
}
.ipo-allot-input {
  border: 1px solid #ed1c24;
  width: 240px;

  padding: 12px 5px;
  border-radius: 5px;
}
.ipo-allo-check-btn {
  background-color: #ed1c24;
  border: 1px solid #ed1c24;
  height: 42px;
  padding: 0 40px;
  border-radius: 5px;
  color: white;
  margin-left: 10px;
}
.ipo-allo-check-btn:hover {
  cursor: pointer;
  filter: brightness(90%);
}
.info-block {
  padding: 20px;
  background: #f5f2f2;
  border-radius: 10px;
  margin: 7px;
}
.info-block span {
  font-size: 13px;
  display: inline-block;
}
.info-label {
  width: 40%;
}
.info-value {
  width: 50%;
  text-align: center;
}
.track-my-design-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  margin: 0 auto;
}

.track-label-new {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #596579;
  /* margin-bottom: 60px; */
  margin-top: 40px;
}


.to-payment-page {
  background-color: #ed1c24;
  border: 1px solid #ed1c24;
  height: 42px;
  padding: 0 40px;
  border-radius: 5px;
  color: white;
  margin-right: 28vw;
  margin-bottom: 50px;
  float: right;
}
.to-payment-page:hover {
  cursor: pointer;
  filter: brightness(90%);
}
@media screen and (max-width: 767px) {
  .ipo-allot-main-input {
    flex-direction: column;
  }
  .track-my-design-container {
    grid-template-columns: 1fr;
  }
}

.flex-item {
  flex-direction: column;
  display: flex !important;
  align-items: center !important;
  margin: 0 !important;
  gap: 10px;
  justify-content: center !important;
}
.info-value {
  text-transform: capitalize !important;
  font-weight: 500;
}
.message-ipo {
  font-size: 14px;
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.ipo-response {
  max-width: 900px;
  min-height: fit-content;
  border-radius: 15px;
  padding: 10px;
  min-width: auto;
  background-color: #f5f2f2;
}
