.grid2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}
.xl-upload-btn{
    margin-top: 15px;
    width: 100% !important;
    cursor:pointer;
}
.xl-upload-div{
    width: 100%;
    cursor: pointer;;
}