.side-bar {
  width: 240px;
  float: left;
  height: 100vh;
  margin: 0%;
  overflow: auto;
}
.top {
  text-align: center;
  margin-top: 24px;
}
.logosize {
  height: 130px;
  width: 130px;
}
.text01 {
  font-size: 20px;
  line-height: 33px;
  margin-top: 0%;
  color: #f2f2f2;
}
.text02 {
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-left: 12px;
  vertical-align: baseline;
  color: #fff;
  padding: 0px 5px;
}
.makeStyles-drawerPaper-4 {
  width: inherit !important;
  max-width: inherit !important;
}
/* @media screen and (max-width: 1200px) {
  .makeStyles-drawerPaper-4 {
    width: 25vw !important;
  }
} */
@media screen and (max-width: 600px) {
  .side-bar {
    width: 200px;
  }
}
.MuiDrawer-paper {
  background-color: #cd171f !important;
  /* color: white; */
}
.MuiListItem-root:hover {
  background: #e61924 !important;
  color: #f2f2f2;
  border-left: 4px solid #fff;
  /* padding-left: 12px; */
}
.MuiListItemButton-root:hover {
  background: #e61924 !important;
  color: #f2f2f2;
  border-left: 4px solid #fff;
  /* padding-left: 12px; */
}
.css-ishquk-MuiButtonBase-root-MuiListItemButton-root {
  padding: 8px 16px 8px 32px !important;
}
.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root {
  padding: 8px 16px !important;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a11219;
  border-radius: 10px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.makeStyles-root-2 {
  width: inherit !important;
  max-width: inherit !important;
}
.MuiDrawer-root,
.MuiDrawer-docked,
.makeStyles-drawer-3 {
  width: inherit !important;
  max-width: inherit !important;
}
#sidebars {
  color: white;
}

.jss4 {
  width: inherit;
}
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
