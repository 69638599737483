.printable-area {
  display: block !important;
}
.print-btn {
  margin-top: 10px;
  background: #ed1c24;
  border-radius: 5px;
  border: none;
  margin: 10px;
  color: white;
  padding: 12px 25px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  float: right;
}
.three-btn {
  width: 350px;
  margin: 20px;
  cursor: pointer;
  background: #ed1c24;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.three-btn:hover {
  background-color: #dc5d62;
}
.button_show {
  padding: 10px;
  background-color: blue;
}

/* view form content css  */
.view-form-content {
  display: flex;
  gap: 1rem;
}

.view-form-formik {
  flex: 1;
  height: 800px;
  overflow-y: auto;
}
.document-detail-dat {
  flex: 2;
  /* height: fit-content; */
  height: 800px;
  overflow-y: auto;
}

.view-apply-form1 {
  padding: 10px;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 20px;
}
.status-ipo {
  font-size: 20px;
}

@media screen and (max-width: 660px) {
  .view-apply-form1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .view-form-content {
    flex-direction: column;
  }
}
.display-print {
  display: none;
}
/* .display-normal{
  display: none;
} */

@media print {
  .display-print {
    display: block;
  }
  .display-print section {
    break-after: always;
    height: 97.5vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .display-normal {
    display: none;
  }
  .document-detail-dat,
  .view-form-formik {
    height: fit-content;
  }
  /* .view-form-content section {
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  } */
  .document-detail-dat {
    border: none;
  }
  .sidebar {
    display: none;
  }
  .top-bar {
    display: none;
  }
  /* .printable-area {
    display: block;
  } */
  .print-btn {
    display: none;
  }
  .MuiCheckbox-root {
    display: none;
  }
}
