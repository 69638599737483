.apply-share-form {
  background: #ffffff;
  border: 2px solid #cfd0d7;
  box-sizing: border-box;
  border-radius: 10px;
}

.iib-border-bottom {
  border-bottom: 2px solid #cfd0d7;
  padding: 0px 10px;
}

.share-apply-form1 {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 20px;
}
.form-input-share label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;

  color: #795959;
}
.form-input-share input {
  width: 100%;
  background: #fcfcfd;
  height: 40px;
  border: 2px solid #cfd0d7;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
  padding: 0px 15px;
}
.form-input-share input:focus {
  outline: 0.5px solid #cfd0d7;
}

.form-input-share button {
  margin-top: 10px;
  background: #ed1c24;
  border: none;
  border-radius: 5px;
  color: white;
  text-align: center;
  width: 100%;
  min-height: 40px;
  padding: 10px 20px;
}
.form-input-share button:hover {
  cursor: pointer;
  background: #cb242a;
}

/* 2nd row of form  */

.personal-info-form {
  margin-top: 15px;
}
.isminor-checkbox input {
  margin-top: 10px;
}

.isminor-checkbox label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;

  color: #596579;
}
.next-but-pubform {
  margin-top: 2rem;
  background: #ed1c24;
  border: none;
  border-radius: 5px;
  color: white;
  text-align: center;
  float: right;
  padding: 15px 30px;
  transition: all 0.2s;
}
.next-but-pubform:hover {
  cursor: pointer;
  background: #df262c;
}
.err-message {
  color: #df262c;
  font-size: 14px;
}

/* R
  e
  s
  p
  o
  n
  s
  i
  v
  e of public and agent form */
@media screen and (max-width: 1086px) {
  .share-apply-form1 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .share-apply-form1 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 660px) {
  .next-but-pubform {
    margin-bottom: 50px;
  }
  .apply-for-issue1 {
    width: 90%;
  }
  .ipoissued-body {
    padding: 10px;
  }
  .ipoissued-main-logo {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .share-apply-form1 {
    grid-template-columns: 1fr;
  }
  .apply-for-issue1 {
    width: 95%;
  }
  .next-but-pubform:hover {
    cursor: pointer;
    background: #df262c;
  }
  .err-message {
    color: #df262c;
  }
}

/* s
   e
   l
   e
   c
   t tag css  */
/* .form-select-tag {
  margin-top: 10px;
} */
/* .form-select-tag .css-1s2u09g-control {
  border: 2px solid #cfd0d7;
}
.form-select-tag .css-1pahdxg-control {
  border: 2px solid #cfd0d7;
}
.form-select-tag .css-1pahdxg-control {
  border: 2px solid;
  border-color: hsl(0, 0%, 80%);
  box-shadow: 0 0 0 1px hsl(0, 0%, 80%);
} */
/* .css-319lph-ValueContainer {
  height: 40px;
} */
/* .css-14el2xx-placeholder {
  padding-bottom: 15px;
} */

/* c
   i
   t
   i
   z
   e
   n
   ship detail head txt */
#citizen-det-p {
  padding-left: 10px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
#spa-fi-up-p {
  color: #a7b2c34a;
}
.upload-btn-wrapper button {
  border: 2px dashed gray;
  color: #596579;
  background: #fcfcfd;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper input[type="file"]:hover {
  cursor: pointer;
}
.ctzenshp-img-frame {
  margin-top: 10px;
  border: 2px solid #cfd0d7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.ctzenshp-img-frame img {
  width: 100%;
  height: 150px;
}

/* Radio input gender */

.input-gender {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.input-gender label:first-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #795959;
}
.input-gender2 {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-gen-radio1 {
  display: flex;
  align-items: center;
}
.input-gen-radio1 input {
  margin: 0;
  margin-right: 5px;
}
.input-gen-radio1 label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;

  color: #795959;
}
.ipoissued-main-logo {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.ipoissued-main-logo img:hover {
  cursor: pointer;
}

.ipoissued-ml-buttons {
  display: flex;
  gap: 2rem;
}
.citizenship-pic-field {
  width: 64vw;
}
